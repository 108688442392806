/* Styles specific to the navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #111111; /* Slightly lighter than black */
}

/* Navbar link and dropdown item colors */
.navbar .navbar-nav .nav-link,
.navbar .navbar-nav .nav-item .dropdown-toggle,
.navbar .navbar-brand {
  color: #ffffff !important;
}

/* Hover color for links */
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-item .dropdown-toggle:hover {
  color: #cccccc !important;
}

/* Dropdown menu styling */
.navbar .dropdown-menu {
  background-color: #111111; /* Matches the navbar background */
  border: none;
}

.navbar .dropdown-item {
  color: #ffffff !important;
}

.navbar .dropdown-item:hover {
  background-color: #333333;
  color: #ffffff;
}

/* Responsive styling for mobile view */
@media (max-width: 992px) {
  /* Navbar toggler icon without a background color */
  .navbar-toggler {
    border-color: transparent;
  }
  
  .navbar-toggler-icon {
    background-image: none; /* Clears default icon */
    width: 30px;
    height: 2px;
    background-color: #ffffff; /* Adds a simple white bar */
    position: relative;
  }

  /* Custom navbar toggler icon lines */
  .navbar-toggler-icon::before,
  .navbar-toggler-icon::after {
    content: '';
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
  }
  
  .navbar-toggler-icon::before {
    top: -8px; /* Position the top line */
  }
  
  .navbar-toggler-icon::after {
    top: 8px; /* Position the bottom line */
  }

  /* Ensure the navbar-collapse has a full width and black background */
  .navbar-collapse {
    background-color: #111111;
    position: absolute;
    top: 60px;
    right: 0; /* Align to the right */
    width: auto; /* Fit to the content width */
    padding: 10px 20px;
  }

  /* Align navbar items to the right */
  .navbar-nav {
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
    padding-left: 0;
  }

  /* Ensure dropdown menus are aligned on the right in mobile */
  .navbar .dropdown-menu {
    position: static;
    float: none;
    width: 100%;
    background-color: #111111; /* Matches navbar color */
    text-align: right; /* Align text to the right */
  }
}

.navbar .navbar-nav {
  margin-left: auto; /* Pushes the navigation items to the right */
}

/* Adds margin to each nav item */
.navbar .nav-item {
  margin-right: 10px; /* Adds space between items */
}