.coming-soon-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #333;
    color: #fff;
    text-align: center;
  }
  
  .coming-soon-page h1 {
    font-size: 4rem;
    margin: 0;
  }
  